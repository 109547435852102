import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TopHeader from "./TopHeader";
import MainHeader from "./MainHeader";
import NavigationBar from "./NavigationBar";
import NotificationMessage from "./NotificationMessage";
import { getAdsectiondetails } from "redux/actions";
import { getLocalStorageValue } from 'config/helper';
import TagManager from 'react-gtm-module'

const Header = () => {

  const dispatch = useDispatch();

  const faviconIcon = useSelector(
    ({ common }) => common.commonDetailsData.header_section.rsa_client_favicon
  );

  const headerSection = useSelector(
    ({ common }) => common.commonDetailsData.header_section
  );

  const adsSectionData = useSelector(({ adsection }) => adsection.adsData);

  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const clientId = getLocalStorageValue("RSAclient-id");

  const getFaviconEl = () => {
    return document.getElementById("favicon");
  };

  useEffect(() => {
    const favicon = getFaviconEl(); // Accessing favicon element
    favicon.href = faviconIcon;
  }, [faviconIcon]);

  useEffect(() => {
    const body = {
      ClientStoreId: +storeId || 1,
      RSAClientId: clientId,
    };
    adsSectionData && !adsSectionData.length && dispatch(getAdsectiondetails(body));
  }, [dispatch]); // eslint-disable-line


  useEffect(()=>{

    if(headerSection.analytic_google_tag_manager_id){
      const tagManagerArgs = {
        gtmId: `${headerSection.analytic_google_tag_manager_id}`
      }
  
      TagManager.initialize(tagManagerArgs)
  }
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  
  return (
    <>
      <NotificationMessage />
      <TopHeader />
      <MainHeader />
      <NavigationBar />
    </>
  );
};

export default Header;
