import React from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick/lib/slider";
import { NextArrow, PrevArrow } from "components/Common/Arrows";
import TestimonialCard from "./TestimonialCard";
import "./testimonials-section.scss";

const TestimonialsSection = () => {
  const testimonialsData = useSelector(
    ({ homepage }) => homepage.bannerAndTestimonialsData.testimonials_section
  );

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <div>
      {!!testimonialsData && testimonialsData.testimonials_list.length > 0 ? (
        <div className="testimonials-wrapper">
          <div className="container">
            <div className="page-title">
              <p>{testimonialsData.title}</p>
              <h2>{testimonialsData.sub_title}</h2>
            </div>
            <div className="sub-container">
              <Slider {...settings}>
                {testimonialsData.testimonials_list.map((testimonial) => {
                  return (
                    <TestimonialCard
                      testimonial={testimonial}
                      key={testimonial.testimonials_id}
                    />
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
      ) : (
        <div className="testimonial-space"></div>
      )}
    </div>
  );
};
export default TestimonialsSection;
