import React, { useEffect } from "react";
import "./job-providesection.scss";
import CustomBanner from "./CustomBanner/custombanner";
import LeftImageRightText from "./LeftImageRightText/leftimagerighttext";
import LeftTextRightImage from "./LeftTextRightImage/lefttextrightimage";
import CenterImage from "./CenterImage/CenterImage";
import CustomProvider from "./CustomProvider";
import CustomInformation from "./CustomInformation";

const CustomProvideSection = ({ pageData }) => {
  useEffect(() => {
    if (pageData.redirect_to_other_page) {
      window.location.assign(pageData.redirect_to_other_page);
    }
  }, [pageData]);

  return (
    <div className="provide-section-page">
      {(() => {
        return pageData.custom_page_section_arr.sort((a, b) => a.position > b.position).map((section, index) => {
          switch (section.section_name) {
            case "banner_image":
              return (
                <CustomBanner key={section.cms_new_pages_section_id} section={section} />
              );
            case "left_image_and_right_text":
              return (
                <LeftImageRightText key={section.cms_new_pages_section_id} section={section} />
              );
            case "left_text_and_right_image":
              return (
                <LeftTextRightImage key={section.cms_new_pages_section_id} section={section} />
              );
            case "center_image_and_bottom_text":
              return (
                <CenterImage key={section.cms_new_pages_section_id} section={section} />
              );
            case "image_and_text_blocks_3_per_row":
              return (
                <CustomProvider section={section} />
              );
            case "stats_section":
              return (
                <CustomInformation section={section} />
              );
            default:
              return null;
          }
        });
      })()}
    </div>
  );
};
export default CustomProvideSection;
