import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useOnClickOutside from "hooks/useOnClickOutside";
import { getLocalStorageValue, setLocalStorageValue } from "config/helper"

const MyAccount = () => {
  const refUserMenuDropdown = useRef();
  const userFirstName = getLocalStorageValue("first-name").charAt(0).toUpperCase() + getLocalStorageValue("first-name").slice(1);
  const userLastName = getLocalStorageValue("last-name").charAt(0);

  const [accountDropdown, setAccountDropdown] = useState(false);

  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);

  useOnClickOutside(refUserMenuDropdown, () => setAccountDropdown(false));

  const {
    reorder_section: { has_visible_reorder_menu },
  } = useSelector(({ common }) => common.commonDetailsData);


  const handleSignOut = () => {
    const store = getLocalStorageValue("selected-store-id");
    localStorage.clear();
    setLocalStorageValue('selected-store-id', store);
    window.reload();
  };

  return (
    <div
      className="my-account"
      onClick={() => setAccountDropdown(!accountDropdown)}
    >
      <div>
        <i className="fa-regular fa-circle-user"></i>
      </div>
      <div>
        <span>
          {isUserLoggedIn ? (
            <span className="username-text">
              Hi,{userFirstName} {userLastName} <br></br> Account
            </span>
          ) : (
            "My Account"
          )}
        </span>
      </div>

      <div
        ref={refUserMenuDropdown}
        className={
          accountDropdown
            ? "my-account-dropdown dropdown-show"
            : "my-account-dropdown dropdown-hidden"
        }
      >
        <div className="my-account-profile-dropdown">
          <Link to="/myaccount">Account Info</Link>
          {/* <Link to="/order-tracking">My Orders</Link>
          <Link to="/my-voucher">My Voucher</Link> */}
          {/* <Link to="/myaccount">My Wishlist</Link> */}
          <Link to="/coupon-view-cart">Clipped Coupons</Link>
          {isUserLoggedIn && (has_visible_reorder_menu === "1") && (
            <Link to="/department/reorder">Re-Order</Link>
          )}
          <Link to="/" onClick={handleSignOut}>
            Sign out
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;