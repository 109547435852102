import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

import "./top-header.scss";

const TopHeader = () => {
  const {
    support_contact_number,
    header_middle_content,
    facebook_url,
    twitter_url,
    instagram_url,
    youtube_url,
  } = useSelector(
    ({ common }) => common.commonDetailsData.header_section || {}
  );

  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);

  return (
    <div className="top-header">
      <div className="container">
        <div className="grid">
          <div className="need-help">
            <div className="sub-title">
              <p>
                {support_contact_number === ""?"" :"Need help? Call Us: "}
                <a href={`tel:+${support_contact_number}`} rel="noreferrer">
                  {support_contact_number}{" "}
                </a>
              </p>
            </div>
          </div>
          <div className="headline">
            <div className="sub-title">
              <p> {header_middle_content} </p>
            </div>
          </div>
          <div className="links-wrapper">
            {(facebook_url || twitter_url || instagram_url || youtube_url) && (
              <div className="follow-us">
                <div>
                  <span>Follow us</span>
                </div>
                <div>
                  {facebook_url && (
                    <a href={facebook_url} rel="noreferrer" target="_blank">
                      <div>
                        <i className="fa-brands fa-facebook-f"></i>
                      </div>
                    </a>
                  )}
                  {twitter_url && (
                    <a href={twitter_url} rel="noreferrer" target="_blank">
                      <div>
                      <i className="fa-brands fa-x-twitter"></i>
                      </div>
                    </a>
                  )}
                  {instagram_url && (
                    <a href={instagram_url} rel="noreferrer" target="_blank">
                      <div>
                        <i className="fa fa-instagram"></i>
                      </div>
                    </a>
                  )}
                  {youtube_url && (
                    <a href={youtube_url} rel="noreferrer" target="_blank">
                      <div>
                        <i className="fa fa-youtube-play"></i>
                      </div>
                    </a>
                  )}
                </div>
              </div>
            )}

            {!isUserLoggedIn ? (
              <div className="auth-links">
                <ul>
                  <NavLink to="/signup">
                    <li>Register</li>
                  </NavLink>
                  <NavLink to="/login">
                    <li>Sign in</li>
                  </NavLink>
                </ul>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
