import { Routes as ReactRoutes, Route, Navigate } from "react-router-dom";
import HomePage from "./home";
import LoginPage from "./login";
// import NotFoundPage from "./not-found";
import SingUpPage from "./sign-up";
import AboutUsPage from "./about-us";
import History from "./history";
import GivingBack from "./giving-back";
import Awards from "./awards";
import Rewards from "./rewards";
import ContactUsPage from "./contact-us";
import IndividualDepartment from "./individual-department";
import EmploymentPage from "./employment";
import ProductDescription from "./product-description";
import CateringProductDescription from "./catering-product-description";
import CustomListItems from "./my-custom-list/customlist-items";
import MyCustomList from "./my-custom-list";
import ProductsPage from "./search/Product";
import CouponPage from "./search/Coupons";
import SearchPage from "./search";
import WeeklyAds from "./weekly-ads";
import WeeklySingleAds from "./weekly-ads/WeeklySingleAds";
import YourCart from "./your-cart";
import Checkout from "./checkout";
import CouponDepartment from "./coupon-department";
import ContactUs from "./contact";
import Mobileapp from "./mobile-app";
import Coupon from "./coupon";
import OrderConfirmation from "./order-confirmation";
import CheckoutInvoice from "./checkout-invoice";
import Couponviewcart from "./couponview-cart";
import Pages from "./pages";
import BestSeller from "./feature-product";
import Career from "./career";
import MyAccount from "./my-account";
import { useSelector } from "react-redux";
import ForgotPassword from "./forgot-password";
import ReorderDepartment from "./reorder-department";
import DonationPage from "./donation";
import Blog from "./blog";
import StoreDetails from "./store-details";
import Campaign from "./campaign";
import Catering from "./catering-meals";
import RecipesPage from "./recipes";
import RecipeDescription from "./recipes-description";
import EBTCheckoutInformation from "./ebtCheckoutInformation";
import ProtectedRoute from "./ProtectedRoute";
import CustomPages from "./custom-pages";
import FeaturedOpenings from "./future-openings/featured-openings";
import FutureOpenings from "./future-openings";

const Routes = () => {
  const {
    ecom_section: { allow_ecommerce = "" },
    reorder_section: { has_visible_reorder_menu = "" },
  } = useSelector(({ common }) => common.commonDetailsData);
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);

  const menuData = useSelector(({ common }) => common.commonDetailsData.menu);
  const footerSectionData = useSelector(({ common }) => common.commonDetailsData.footer_section);

  const urlString = window.location.pathname;
  const splittedUrlPath = urlString.split("/").filter((each) => each !== "");
  const search_menu_url_String = splittedUrlPath.length === 1 && splittedUrlPath[0];

  const submenuFiltered = menuData.filter((each) => {
    // console.log(splittedUrlPath);
    // console.log("searchString", searchString);
    if (search_menu_url_String) {
      return (
        each.menu_url === search_menu_url_String ||
        each.submenu.some((each) => each.menu_url === search_menu_url_String)
      );
    }
  });
  //console.log("footerSectionData >> ",footerSectionData);

  //KS: 2023-07-19 add footerSectionMyAccountFiltered, footerSectionHelpSectionFiltered, footerSectionCompanySectionFiltered and footerSectionFilteredFlag
  const footerSectionMyAccountFiltered = footerSectionData.my_account_section.pages.filter((each) => {
    // console.log("searchString", searchString);
    if (search_menu_url_String) {
      return ( 
        each.url === search_menu_url_String
      );
    }
  });

  const footerSectionHelpSectionFiltered = footerSectionData.help_section.pages.filter((each) => {
    // console.log("searchString", searchString);
    if (search_menu_url_String) {
      return ( 
        each.url === search_menu_url_String
      );
    }
  });

  const footerSectionCompanySectionFiltered = footerSectionData.company_section.pages.filter((each) => {
    // console.log("searchString", searchString);
    if (search_menu_url_String) {
      return ( 
        each.url === search_menu_url_String
      );
    }
  });

  const footerSectionFilteredFlag = (footerSectionMyAccountFiltered.length > 0 || footerSectionHelpSectionFiltered.length > 0 || footerSectionCompanySectionFiltered.length > 0)?1:0;
  
  //console.log("footerSectionFilteredFlag >> ",footerSectionFilteredFlag);
    //console.log("submenuFiltered >> ",submenuFiltered);

  const token = localStorage.getItem("user-token");
  return (
    <div>
      <ReactRoutes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/home" element={<HomePage />} />
        <Route
          exact
          path="/login"
          element={token ? <Navigate to="/" /> : <LoginPage />}
        />
        <Route exact path="/signup" element={<SingUpPage />} />

        <Route exact path="/checkout" element={<Checkout />} />
        <Route exact path="/checkout/:checkoutType" element={<Checkout />} />
        <Route exact path="/mobapp" element={<Mobileapp />} />
        <Route exact path="/myaccount" element={<MyAccount />} />
        <Route exact path="/myaccount/:menu" element={<MyAccount />} />
        <Route exact path="/coupon-view-cart" element={<Couponviewcart />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />

        <Route exact path="/campaign/:campaign_slug" element={<Campaign />} />
        <Route
          exact
          path="/catering-meal/:departmentSlug"
          element={<Catering />}
        />
        <Route exact path="/catering-meal" element={<Catering />} />
        <Route
          exact
          path="/checkout-invoice/:invoiceType/:mealKitId"
          element={<CheckoutInvoice />}
        />
        <Route
          exact
          path="/checkout-invoice/:orderid"
          element={<CheckoutInvoice />}
        />

        <Route
          exact
          path="/checkout-invoice/:orderid"
          element={<CheckoutInvoice />}
        />
        <Route
          path="/"
          element={<ProtectedRoute submenuFiltered={submenuFiltered} footerSectionFilteredFlag={footerSectionFilteredFlag}  />}
        >
          <Route exact path="/about-us" element={<AboutUsPage />} />
          <Route exact path="/weekly-ads" element={<WeeklyAds />} />
          <Route exact path="/contact-us" element={<ContactUs />} />
          <Route exact path="/coupons" element={<Coupon />} />
          <Route exact path="/careers" element={<Career />} />
          <Route exact path="/best-seller" element={<BestSeller />} />
          <Route exact path="/rewards" element={<Rewards />} />
          <Route exact path="/history" element={<History />} />
          <Route exact path="/store-details" element={<StoreDetails />} />
          <Route exact path="/giving-back" element={<GivingBack />} />
          <Route exact path="/blog" element={<Blog />} />
          <Route exact path="/donation" element={<DonationPage />} />
          <Route exact path="/awards" element={<Awards />} />
          <Route exact path="/locations" element={<ContactUsPage />} />

          <Route path={`employment`} element={<EmploymentPage />}>
            <Route path=":jobId" element={<EmploymentPage />} />
          </Route>
          <Route exact path="/featured-openings" element={<FutureOpenings />} />
        </Route>

        <Route
          exact
          path="/order-confirmation"
          element={<OrderConfirmation />}
        />

        <Route exact path="/pages/:slug" element={<Pages />} />
        <Route exact path="/custom-pages/:slug" element={<CustomPages />} />
        <Route exact path="/company-policies/:slug" element={<Pages />} />

        <Route
          exact
          path="/weekly-ads/:storeid/:storename"
          element={<WeeklySingleAds />}
        />

        <Route path={`search`} element={<SearchPage />}>
          <Route path="products/:searchKeyWord" element={<ProductsPage />} />
          <Route path="products/" element={<ProductsPage />} />
          <Route path="coupons/:searchKeyWord" element={<CouponPage />} />
          <Route path="coupons/" element={<CouponPage />} />
        </Route>

        {/* <Route path={`employment`} element={<EmploymentPage />}>
          <Route path=":jobId" element={<EmploymentPage />} />
        </Route> */}
        {/* moved to protected route */}

        <Route
          exact
          path="/coupon-department/:departmentSlug"
          element={<CouponDepartment />}
        />

        <Route
          exact
          path="/your-cart"
          element={isUserLoggedIn ? <YourCart /> : <LoginPage />}
        />
        <Route
          exact
          path="/your-cart/:cartType"
          element={isUserLoggedIn ? <YourCart /> : <LoginPage />}
        />
        {allow_ecommerce === "1" && (
          <Route
            exact
            path={`/departments/:departmentSlug`}
            element={<IndividualDepartment />}
          />
        )}

        {has_visible_reorder_menu === "1" && (
          <Route
            exact
            path={`/department/:departmentSlug`}
            element={<ReorderDepartment />}
          />
        )}
        <Route
          exact
          path={`/profile`}
          element={isUserLoggedIn ? <MyAccount /> : <LoginPage />}
        />
        <Route
          exact
          path={`/order-history`}
          element={isUserLoggedIn ? <MyAccount /> : <LoginPage />}
        />
        <Route
          exact
          path={`/wishlist`}
          element={isUserLoggedIn ? <MyAccount /> : <LoginPage />}
        />

        <Route
          exact
          path={`/balance/ebt-confirmation/:ebtBalance`}
          element={isUserLoggedIn ? <MyAccount /> : <LoginPage />}
        />

        <Route
          exact
          path="/product-description/:departmentSlug/:productSlug"
          element={<ProductDescription />}
        />

        <Route
          exact
          path="/catering-product-description/:departmentSlug/:productSlug"
          element={<CateringProductDescription />}
        />

        <Route
          exact
          path="/list-items"
          element={isUserLoggedIn ? <MyCustomList /> : <LoginPage />}
        />
        <Route
          exact
          path="/list-items/:id"
          element={isUserLoggedIn ? <CustomListItems /> : <LoginPage />}
        />

        <Route path={`recipes`} element={<RecipesPage />}>
          <Route path=":searchKeyWord" element={<RecipesPage />} />
        </Route>

        <Route
          exact
          path="/recipe-description/:recipeId"
          element={<RecipeDescription />}
        />

        <Route
          exact
          path="/checkout/ebt-confirmation/:orderId"
          element={<EBTCheckoutInformation />}
        />

        <Route path="*" element={<HomePage />} />
      </ReactRoutes>
    </div>
  );
};

export default Routes;
